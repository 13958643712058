<template>
  <div
    class="displayBetween"
    style="height: 100%; width: 100%"
    id="sd_rightTwo"
  ></div>
</template>

<script>
import { yuanhuan2 } from "@/utils/echarts/shudi/yuanhuan2";
import {getAbnormalNum} from "@/api/index/index";
  
export default {
  data() {
    return {
      autoTime: null,
      myChart:null
    }
  },
  methods: {
    async getInfo(){
      let Res = await getAbnormalNum({
        aggField: "platform_type",
        dictField: "platform_type"
      });
      let ResData = Res?.data?.data || [];
      this.setEcharts("sd_rightTwo", ResData);
    },
    setEcharts(domName, ResData) {
      let { myChart, timeCom} = yuanhuan2(domName, ResData , { 
      myChart: this.myChart || null,
      timeCom: this.autoTime || null,
    });
    this.myChart = myChart;
    this.autoTime = timeCom
    },
  },
  mounted() {
      this.getInfo()
  },
  beforeDestroy() {
      clearInterval(this.autoTime)
  }
}
</script>

<style></style>
