<template>
  <div class="displayBetween" style="height: 100%; width: 100%;" id="sd_cenetrBottm"></div>
</template>

<script>
import { zhuzi } from "@/utils/echarts/shudi/zhuzi";
import { publishDataStat } from '@/api/daping/index'
import myEchartFn from "@/utils/echarts/index";
export default {
  data() {
    return {
      autoTime: null,

    };
  },
  methods: {
    async getInfo(dateType = 1) {
      let Res = await publishDataStat(dateType);
      let ResData = Res?.data?.data || [];
      this.setEcharts("sd_cenetrBottm", ResData, dateType);
    },
    setEcharts(domName, ResData, dateType) {
      if (this.autoTime) {
        clearInterval(this.autoTime);
        this.autoTime = null;
      }
      let myChart = myEchartFn.getMyChartok(domName);
      let dataXlEN = ResData.length;
      if (dateType == 3) dataXlEN = 6;
      if (dateType == 4) dataXlEN = 7;
      if (dateType == 2) dataXlEN = 7;
      if (dateType == 1) dataXlEN = 7;
      let { dataX, dataArr, dataX2, dataArr2 } = myEchartFn.getSwiperItem(
        ResData,
        dataXlEN
      );
      let opinion = zhuzi(dataX, dataArr, {
        left: "2%",
        bottom: "0%",
        pictorialBar: "pictorialBar",
      });
      myChart.setOption(opinion);
      window.addEventListener("resize", () => {
        myChart.resize();
      });
      if (dateType == 1) return;
      if (dateType == 3) return;
      this.autoTime = setInterval(() => {
        myEchartFn.autoMove(
          { dataX, dataArr, dataX2, dataArr2 },
          opinion,
          myChart,
          this.autoTime
        );
      }, 1000 * 2);
    },
  },
  mounted() {
    this.getInfo();
  },
  beforeDestroy() {
    clearInterval(this.autoTime);
  },
};
</script>

<style scoped></style>
